<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <coozzy-card class="global-card no-top-border">
      <div
        v-b-toggle.collapse-baseData
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.baseData') }}
        </h5>
      </div>
      <div class="col-12 pl-0">
        <b-collapse
          id="collapse-baseData"
          visible>
          <div class="row">
            <div class="col-sm-12 col-md-9">
              <div class="row details-row-bg-1 mt-3 py-2">
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-input
                    v-model="property.name"
                    :is-read-only="readOnly"
                    :disabled="isJanitor"
                    :state="$v.property.name.$dirty && $v.property.name.$error ? false : null"
                    :limit="30"
                    :name="$t('message.propertiesView.name') + (!readOnly ? '*' : '')" />
                </div>
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-input
                    v-model="numericId"
                    :disabled="true"
                    :is-read-only="readOnly"
                    :name="$t('message.propertiesView.number')" />
                </div>
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-country-select
                    v-model="property.address.country"
                    :initial-value="property.address.country"
                    :is-read-only="readOnly"
                    :disabled="isJanitor" />
                </div>
              </div>
              <div class="row details-row-bg-2 py-01rem">
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-input
                    v-model="property.address.zip"
                    :is-read-only="readOnly"
                    :type="property.address.country === 'DE' || property.address.country === 'CH'? 'number' : 'text'"
                    :limit="zipLength"
                    :state="$v.property.address.zip.$dirty && $v.property.address.zip.$error ? false : null"
                    :name="$t('message.generic.zip') + (!readOnly ? '*' : '')"
                    :disabled="isJanitor"
                    @input="getCityFromZip" />
                </div>
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-input
                    v-if="!isCitiesSuggested"
                    v-model="property.address.city"
                    :is-read-only="readOnly"
                    :disabled="isJanitor"
                    :name="$t('message.generic.city') + (!readOnly ? '*' : '')" />
                  <template v-else>
                    <label for="type">{{ $t('message.generic.city') }}*</label>
                    <coozzy-form-select
                      id="city"
                      :state="$v.property.address.city.$dirty && $v.property.address.city.$error ? false : null"
                      :disabled="isJanitor"
                      @change="cityChanged">
                      <option
                        v-for="(item, typeIndex) in citiesValue"
                        :key="typeIndex"
                        :value="item">
                        {{ item.city }}
                      </option>
                    </coozzy-form-select>
                  </template>
                </div>
                <div class="col-sm-12 col-md-4">
                  <coozzy-form-region-select
                    v-model="property.address.state"
                    :initial-value="property.address.state"
                    :is-read-only="readOnly"
                    :disabled="isJanitor"
                    :country="property.address.country" />
                </div>
              </div>
              <div class="row details-row-bg-1 py-01rem">
                <div class="col-sm-12 col-md-4">
                  <label for="type">{{ $t('message.propertiesView.propertyType.title') }}{{ (!readOnly ? '*' : '') }}</label>
                  <coozzy-form-select
                    v-if="!readOnly"
                    id="type"
                    v-model="property.type"
                    :disabled="property.type !== 'PROPERTY_TYPE_UNDEFINED' || isJanitor">
                    <option
                      value="">
                      -
                    </option>
                    <option
                      v-for="(type, typeIndex) in typeValue"
                      :key="typeIndex"
                      :value="type">
                      {{ $t(`message.propertiesView.propertyType.${type}`) }}
                    </option>
                  </coozzy-form-select>
                  <span
                    v-else
                    class="show-data">{{ property.type === '' || property.type === null || property.type === 'PROPERTY_TYPE_UNDEFINED' ? '-' : $t(`message.propertiesView.propertyType.${property.type}`) }}</span>
                </div>
                <div class="col-sm-12 col-md-4">
                  <label for="development">{{ $t('message.propertiesView.development.title') }}</label>
                  <coozzy-form-select
                    v-if="!readOnly"
                    id="development"
                    v-model="property.development"
                    :disabled="isJanitor">
                    <option value="PROPERTY_DEVELOPMENT_UNDEFINED" />
                    <option value="PROPERTY_DEVELOPMENT_FULL">
                      {{ $t('message.propertiesView.development.PROPERTY_DEVELOPMENT_FULL') }}
                    </option>
                    <option value="PROPERTY_DEVELOPMENT_PARTIAL">
                      {{ $t('message.propertiesView.development.PROPERTY_DEVELOPMENT_PARTIAL') }}
                    </option>
                    <option value="PROPERTY_DEVELOPMENT_UNDEVELOPED">
                      {{ $t('message.propertiesView.development.PROPERTY_DEVELOPMENT_UNDEVELOPED') }}
                    </option>
                  </coozzy-form-select>
                  <span
                    v-else
                    class="show-data">
                    {{ property.development === '' || property.development === null || property.development === 'PROPERTY_DEVELOPMENT_UNDEFINED' ? '-' : $t(`message.propertiesView.development.${property.development}`) }}
                  </span>
                </div>
                <div
                  v-if="property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
                  class="col-sm-12 col-md-4">
                  <coozzy-form-input
                    v-model="totalPropertyValueQuota"
                    :is-read-only="readOnly"
                    :type="'number'"
                    :name="$t('message.tableColumnHeadings.totalValueQuota')"
                    :disabled="true" />
                </div>
              </div>
              <div class="row details-row-bg-2 py-01rem">
                <div
                  class="col-sm-12 col-md-4 mt-2">
                  <coozzy-form-checkbox
                    v-if="!readOnly"
                    id="opted"
                    v-model="property.opted"
                    :initial="property.opted"
                    :is-read-only="readOnly"
                    :disabled="isJanitor"
                    :state="$v.property.opted.$dirty && $v.property.opted.$error ? false : null"
                    class="align-self-center mt-3">
                    {{ $t('message.onBoarding.buildings.objects.opted') }}
                  </coozzy-form-checkbox>
                  <div
                    v-else>
                    <label>{{ $t('message.onBoarding.buildings.objects.opted') }}</label>
                    <span class="show-data">
                      {{ property.opted ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-md-4">
                  <label for="optedPortionKey">{{ $t('message.propertiesView.optedPortionKey.title') }} {{ (!readOnly && property.opted ? '*' : '') }}</label>
                  <coozzy-form-select
                    v-if="!readOnly"
                    id="optedPortionKey"
                    v-model="property.optedPortionKey"
                    :disabled="!property.opted || isJanitor"
                    :state="property.opted && $v.property.optedPortionKey.$dirty && $v.property.optedPortionKey.$error ? false : null">
                    <option value="">
                      -
                    </option>
                    <option
                      v-for="(optedPortionKeyItem, index) in optedPortionKeyValue"
                      :key="index"
                      :value="optedPortionKeyItem">
                      {{ $t(`message.propertiesView.optedPortionKey.${optedPortionKeyItem}`) }}
                    </option>
                  </coozzy-form-select>
                  <span
                    v-else
                    class="show-data">{{ property.optedPortionKey === '' || property.optedPortionKey === null || property.optedPortionKey === 'OPTED_PORTION_KEY_UNDEFINED' ? '-' : $t(`message.propertiesView.optedPortionKey.${property.optedPortionKey}`) }}</span>
                </div>

                <div class="col-sm-12 col-md-4">
                  <label for="note">{{ $t('message.generic.note') }}</label>
                  <coozzy-form-textarea
                    ref="note"
                    v-model="property.note"
                    :initial="property.note"
                    :value-text-area="property.note"
                    :show-label="false"
                    :is-read-only="readOnly"
                    :disabled="isJanitor"
                    name="note" />
                </div>
              </div>
            </div>
            <div class="d-flex col-sm-12 col-md-3 mt-3 mt-md-0 justify-content-center">
              <coozzy-spinner
                v-if="mediaUploadProcessing"
                class="align-self-center" />
              <div
                v-if="!mediaUploadProcessing && propertyImageMedia"
                class="uploaded-file my-2 w-100"
                :class="[readOnly ? 'cursor-pointer' : '']"
                style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                :style="{ 'background-image': 'url(' + propertyImageMedia.url + ')' }"
                :draggable="true"
                @dragstart="handleDragStart(propertyImageMedia.id)"
                @click="showImage">
                <div
                  :class="['object-image-remove-container', 'cursor-pointer', readOnly || isJanitor ? 'd-none' : '']"
                  @click="deleteMedia()">
                  <coozzy-close-icon class="object-image-remove bg" />
                  <coozzy-close-icon class="object-image-remove" />
                </div>
              </div>
              <img
                v-if="!mediaUploadProcessing && !propertyImageMedia"
                :class="['align-self-center', 'building-image', readOnly || isJanitor ? '' : 'cursor-pointer']"
                src="/img/image-placeholder.png"
                alt="Placeholder"
                @click="uploadImageClicked">

              <coozzy-form-file-input
                v-if="!readOnly && !isJanitor"
                ref="mediaUpload"
                class="d-none"
                accept="image/png,image/jpeg,image/jpg"
                @change="addFile" />
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && !isJanitor"
      class="global-card">
      <property-rent-components
        :read-only="readOnly"
        :property-selected="property"
        :expand-by-default="false" />
    </coozzy-card>
    <coozzy-card class="global-card">
      <div
        v-b-toggle.collapse-property-device
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.device') }}
          <template v-if="devicesLoading">
            <coozzy-spinner :small="true" />
          </template>
          <template v-else>
            ({{ devices.length }})
          </template>
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-property-device"
          :visible="openedCollapses.includes('collapse-property-device')"
          @show="renderDevicesAnimation=true"
          @shown="renderDevices=true"
          @hidden="renderDevices = false">
          <div
            v-if="devices.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.device') }}
              </h6>
            </div>
          </div>
          <template v-if="devices.length > 0 && renderDevicesAnimation === true">
            <coozzy-spinner />
          </template>
          <template v-if="renderDevices">
            <div
              v-for="(device, deviceIndex) in devices"
              :key="`devices-${device.id || device.internalId}`"
              class="row">
              <device
                :key="`device-i-${device.id || device.internalId}`"
                ref="property-device"
                :index="deviceIndex"
                :read-only="readOnly"
                :device="device"
                :target="'property'"
                :external-contact-list="propertyOwnerContacts"
                :owner-id="property?.ownerId"
                :expand-by-default="(('isNewItem' in device) && ((deviceIndex + 1) === devices.length))"
                :add-new-document="device.id && deviceIdNewDocument !== '' && deviceIdNewDocument === device.id"
                @removeConfirmed="removeDevice(deviceIndex)"
                @device-update="$emit('has-change', true)"
                @duplicateDevice="duplicateDevice"
                @selected-device-for-history="openActivities" />
            </div>
          </template>
          <div class="row">
            <div class="col-2 mt-1">
              <coozzy-button
                v-if="!readOnly"
                class="w-100"
                design="prop-green"
                @click="addNewDevice">
                {{ $t('message.onBoarding.devices.newDevice') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-property-insurance
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.insurance') }}
          <template v-if="insurancesLoading">
            <coozzy-spinner :small="true" />
          </template>
          <template v-else>
            ({{ propertyInsurances.length }})
          </template>
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-property-insurance"
          :visible="openedCollapses.includes('collapse-property-insurance') || expandPropertyInsuranceId !== ''">
          <div
            v-if="propertyInsurances.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.insurance') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(insurance, insuranceIndex) in propertyInsurances"
            :key="`insurance-${insurance.id || insurance.internalId}`"
            class="row">
            <building-insurance
              :id="`property_insurance_${insurance.id || insurance.internalId}`"
              :key="`insurance-i-${insurance.id || insurance.internalId}`"
              ref="property-insurance"
              :index="insuranceIndex"
              :read-only="readOnly"
              :insurance="insurance"
              :target="'property'"
              :expand-by-default="(('isNewItem' in insurance) && ((insuranceIndex + 1) === propertyInsurances.length)) || expandPropertyInsuranceId === insurance.id"
              :add-new-document="insurance.id && insuranceIdNewDocument !== '' && insuranceIdNewDocument === insurance.id"
              @removeConfirmed="removeInsurance(insurance.id || insurance.internalId)"
              @change-insurance="$emit('has-change', true)" />
          </div>
          <div class="row">
            <div class="col-2 mt-1">
              <coozzy-button
                v-if="!readOnly"
                class="w-100"
                design="prop-green"
                @click="addNewInsurance">
                {{ $t('message.onBoarding.buildings.insurances.add') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-property-mortgages
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.mortgage') }}
          <template v-if="mortgagesLoading">
            <coozzy-spinner :small="true" />
          </template>
          <template v-else>
            ({{ mortgages.length }})
          </template>
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-property-mortgages"
          ref="collapseMortgage"
          :visible="openedCollapses.includes('collapse-property-mortgages') || expandPropertyMortgageId !== ''">
          <template v-if="mortgagesLoading">
            <coozzy-spinner />
          </template>
          <div
            v-else-if="mortgages.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.mortgage') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(item, index) in mortgages"
            :key="`mortgages-${item.id || item.internalId}`"
            class="row">
            <mortgage
              :id="'property_mortgage_' + item.id || item.internalId"
              ref="property-mortgage"
              :key="`mortgage-${item.id || item.internalId}`"
              :index="index"
              :read-only="readOnly"
              :mortgage="item"
              :expand-by-default="('isNewItem' in item) && ((index + 1) === mortgages.length) || expandPropertyMortgageId === item.id"
              :add-new-document="item.id && mortgageIdNewDocument !== '' && mortgageIdNewDocument === item.id"
              @remove-mortgage="removeMortgage"
              @update-mortgage="updateMortgage" />
          </div>
          <div class="row">
            <div class="col-2 mt-1">
              <coozzy-button
                v-if="!readOnly"
                class="w-100"
                design="prop-green"
                @click="addNewMortgage">
                {{ $t('message.onBoarding.mortgage.newMortgage') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-property-bank-details
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.propertyBankDetails') }}
          <template v-if="bankDetailsLoading">
            <coozzy-spinner :small="true" />
          </template>
          <template v-else>
            ({{ bankDetails.length }})
          </template>
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-property-bank-details"
          ref="collapseBankDetails"
          :visible="openedCollapses.includes('collapse-property-bank-details')">
          <template v-if="bankDetailsLoading">
            <coozzy-spinner />
          </template>
          <div
            v-else-if="bankDetails.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.bankDetails') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(item, index) in bankDetails"
            :key="`bank-details-${index}`"
            class="row">
            <property-bank-details
              ref="property-bank-details"
              :key="`bank-details-${index}`"
              :owner-id="property.ownerId"
              :index="index"
              :read-only="readOnly"
              :bank-details="item"
              :expand-by-default="('isNewItem' in item) && ((index + 1) === bankDetails.length)"
              :add-new-document="propBankIndexNewDocument === index"
              @remove-bank-details="removeBankDetails"
              @update-bank-details="updateBankDetails" />
          </div>
          <div class="row">
            <div class="col-2 mt-1">
              <coozzy-button
                v-if="!readOnly"
                class="w-100"
                design="prop-green"
                @click="addNewBankDetails">
                {{ $t('message.onBoarding.bankDetails.newBankDetails') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-property-settlement
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.settlements') }}
          <template v-if="settlementsLoading">
            <coozzy-spinner :small="true" />
          </template>
          <template v-else>
            ({{ settlements.length }})
          </template>
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-property-settlement"
          :visible="openedCollapses.includes('collapse-property-settlement')">
          <div
            v-if="settlements.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.settlement') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(settlement, settlementIndex) in settlements"
            :key="`settlements-${settlement.id || settlement.internalId}`"
            class="row">
            <settlement-object
              :key="`settlement-i-${settlement.id || settlement.internalId}`"
              ref="property-settlement"
              :index="settlementIndex"
              :read-only="readOnly"
              :settlement="settlement"
              :property="property"
              :target="'property'"
              :external-contact-list="propertyOwnerContacts"
              :expand-by-default="(('isNewItem' in settlement) && ((settlementIndex + 1) === settlements.length))"
              @removeConfirmed="removeSettlement"
              @change-settlement="$emit('has-change', true)" />
          </div>
          <div class="row">
            <div class="col-2 mt-1">
              <coozzy-button
                v-if="!readOnly"
                class="w-100"
                design="prop-green"
                @click="addNewSettlement">
                {{ $t('message.onBoarding.settlementObject.addSettlementObject') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>

    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-property-document
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.propertiesView.documents') }} ({{ propertyDocuments.length }})
        </h5>
      </div>
      <div
        class="col-12 p-0">
        <b-collapse
          id="collapse-property-document"
          :visible="openedCollapses.includes('collapse-property-document')">
          <span
            v-if="propertyDocuments.length > 0"
            class="link downloadAllDocuments"
            @click="downloadAllDocuments($event)">{{ $t('message.marketingMessages.mail.downloadAll') }} <coozzy-file-download-icon /></span>
          <documents-list
            :documents-list="propertyDocuments"
            :is-read-only="readOnly"
            @version-added="versionAdded"
            @document-deleted="deleteDocument" />
          <div class="row">
            <add-document-modal
              :owner-id="property.ownerId"
              @document-created="documentCreated" />
            <div
              v-if="!userWithoutRole"
              class="col-2 mt-1">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="readOnly ? goToEditMode() : addDocumentClicked()">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
  </section>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import Mortgage from '@/properties/components/Mortgage'
import PropertyBankDetails from '@/properties/components/PropertyBankDetails'
import Device from '@/building/tabs/components/Device'
import BuildingInsurance from '@/building/tabs/components/BuildingInsurance'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormFileInput from '../../framework/components/form/fileInput/CoozzyFormFileInput'
import MediaApi from '../../misc/apis/MediaApi'
import ObjectApi from '../../misc/apis/ObjectApi'
import ContactApi from '../../misc/apis/ContactApi'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import Vue from 'vue'
import { decimal, maxLength, minValue, required } from 'vuelidate/lib/validators'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'
import PropertyRentComponents from '../onboarding/PropertyRentComponents.vue'
import SettlementObject from '@/building/tabs/components/SettlementObject'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { isZipValid, validation } from '@/mixins/validation'
import { sort } from '@/mixins/sort'
import { toastError } from '@/mixins/toastError'
import { onboarding } from '@/mixins/onboarding'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { media } from '@/mixins/media'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'
import DocumentsList from '@/properties/components/DocumentsList'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import { address } from '@/mixins/address'
import { formatting } from '@/mixins/formatting'

function copyObject(object) {
  const objCopy = {} // objCopy will store a copy of the mainObj
  let key

  for (key in object) {
    // noinspection JSUnfilteredForInLoop
    objCopy[key] = object[key] // copies each property to the objCopy object
  }
  return objCopy
}

export default {
  name: 'PropertyTab',
  components: {
    CoozzyFileDownloadIcon,
    CoozzyFormTextarea,
    CoozzyFormCheckbox,
    CoozzyFormRegionSelect,
    CoozzyFormCountrySelect,
    CoozzyCloseIcon,
    CoozzySpinner,
    CoozzyFormFileInput,
    CoozzyButton,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyCard,
    AddDocumentModal,
    Mortgage,
    PropertyBankDetails,
    Device,
    BuildingInsurance,
    PropertyRentComponents,
    SettlementObject,
    DocumentsList
  },
  mixins: [validation, toastError, onboarding, sort, media, user, routeChecks, address, formatting],
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('onboarding/setOpenedCollapses')
    next()
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    propertySelected: {
      type: Object,
      default: null
    },
    expandPropertyMortgageId: {
      type: String,
      default: ''
    },
    expandPropertyInsuranceId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCitiesSuggested: false,
      citiesValue: [],
      property: this.propertySelected,
      mediaUpload: null,
      mediaUploadProcessing: false,
      settlementsLoading: false,
      propertyImageMedia: null,
      renderDevices: false,
      renderDevicesAnimation: true,
      devicesLoading: false,
      settlementLoading: false,
      mortgagesLoading: false,
      bankDetailsLoading: false,
      insurancesLoading: false,
      propertyDocuments: [],
      galleryImages: [],
      galleryIndex: null,
      optedPortionKeyValue: ['OPTED_PORTION_KEY_M2', 'OPTED_PORTION_KEY_M3', 'OPTED_PORTION_KEY_VALUE_RATIO', 'OPTED_PORTION_KEY_TV', 'OPTED_PORTION_KEY_ELEVATOR', 'OPTED_PORTION_KEY_LOCATION_COMPENSATION', 'OPTED_PORTION_KEY_UNIT', 'OPTED_PORTION_KEY_NUMBER_OF_INHABITANTS'],
      typeValue: ['PROPERTY_TYPE_RENTAL', 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP', 'PROPERTY_TYPE_COOPERATIVE'],
      availableTypes: [
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET'
      ],
      mortgageDocuments: [],
      mortgages: [],
      bankDetails: [],
      deletedMortgageIds: [],
      deletedBankDetailsIds: [],
      deletedSettlementObjects: [],
      devices: [],
      settlements: [],
      propertyInsurances: [],
      insurancesDocuments: [],
      propertyOwnerContacts: [],
      initRentComponents: null,
      deviceIdNewDocument: '',
      mortgageIdNewDocument: '',
      insuranceIdNewDocument: '',
      propBankIndexNewDocument: '',
      buildingList: [],
      valuesQuota: []
    }
  },
  computed: {
    zipLength() {
      if (this.property) {
        const country = this.property.address.country
        if (country === 'CH') {
          return 4
        } else if (country === 'DE') {
          return 5
        } else {
          return 15
        }
      }
      return -1
    },
    loadingFinish() {
      if (!this.devicesLoading && !this.mortgagesLoading && !this.insurancesLoading) {
        return true
      }
      return false
    },
    numericId() {
      return this.property.numericId
    },
    plotArea: {
      get() {
        return this.property.plotArea !== -1 ? this.property.plotArea : null
      },
      set(value) {
        this.property.plotArea = value
      }
    },
    landRegisterNumber: {
      get() {
        return this.property.landRegisterNumber
      },
      set(value) {
        this.property.landRegisterNumber = value
      }
    },
    totalPropertyValueQuota() {
      return this.formatValueQuota(this.valuesQuota.reduce((accumulator, object) => {
        return accumulator + object.valueQuota
      }, 0))
    }
  },
  watch: {
    property: {
      handler: function (newValue) {
        if (!newValue.opted) {
          newValue.optedPortionKey = ''
        }
        this.$emit('has-change', true)
      },
      deep: true
    },
    mortgages: {
      handler: function (newVal, oldVal) {
        if (oldVal.length !== 0 || (newVal.length > 0 && !newVal[newVal.length - 1].id)) {
          this.$emit('has-change', true)
        }
      },
      deep: true
    },
    openedCollapses: function (newVal) {
      this.renderDevices = newVal.includes('collapse-property-device')
    },
    renderDevices: function (newVal) {
      if (newVal === true) {
        this.$nextTick(function () {
          this.renderDevicesAnimation = false
        })
      }
    },
    devices: {
      handler: function (newVal) {
        if (newVal.length > 0 && !newVal[newVal.length - 1].id) {
          this.$emit('has-change', true)
        }
      },
      deep: true
    },
    loadingFinish: function (newValue) {
      if (newValue) {
        let scrollpos = localStorage.getItem('scrollpos')
        if (scrollpos) window.scrollTo(0, parseInt(scrollpos))
      }
    }
  },
  mounted() {
    if (localStorage.getItem('deviceIdNewDocument')) {
      this.deviceIdNewDocument = localStorage.getItem('deviceIdNewDocument')
    }
    if (localStorage.getItem('insuranceIdNewDocument')) {
      this.insuranceIdNewDocument = localStorage.getItem('insuranceIdNewDocument')
    }
    if (localStorage.getItem('mortgagePropertyIdNewDocument')) {
      this.mortgageIdNewDocument = localStorage.getItem('mortgagePropertyIdNewDocument')
    }
    if (localStorage.getItem('propBankIndexNewDocument')) {
      this.propBankIndexNewDocument = parseInt(localStorage.getItem('propBankIndexNewDocument'))
    }
    if (localStorage.getItem('addGeneralPropertyDocument') === 'true') {
      this.addDocumentClicked()
    }
    document.body.onmousedown = function(e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.getBuildingList()
    this.bankDetails = this.propertySelected.bankInfo || []
    this.initRentComponents = this.property.flatRateRentComponents.concat(this.property.advancePaymentRentComponents).concat(this.property.additionalRentComponents).flatMap(x => x.rentComponent)
    this.property.optedPortionKey = this.propertySelected.optedPortionKey !== '' && this.propertySelected.optedPortionKey !== 'OPTED_PORTION_KEY_UNDEFINED' && this.propertySelected.optedPortionKey !== null ? this.propertySelected.optedPortionKey : ''
    this.property.type = this.propertySelected.type !== '' && this.propertySelected.type !== 'PROPERTY_TYPE_UNDEFINED' && this.propertySelected.type !== null ? this.propertySelected.type : ''
    if (this.property.documentIds.length > 0) {
      MediaApi.listDocuments(this.property.documentIds)
        .then(response => {
          this.propertyDocuments = response.documents
        })
    }
    if (this.property.imageMediaId !== '') {
      MediaApi.getMediaByID(this.property.imageMediaId)
        .then(response => {
          this.propertyImageMedia = response.media
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
        })
    }

    this.devicesLoading = true
    ObjectApi.getDevicesByReferenceIds([this.property.id])
      .then(response => {
        this.devices = response.devices.sort(
          function(a, b) {
            if (a.uninstallation === b.uninstallation) {
              return a.numericId > b.numericId ? 1 : -1
            }
            return a.uninstallation === null ? -1 : 1
          })
        this.devicesLoading = false
        this.$nextTick(() => {
          this.$emit('has-change', false)
        })
      })
    this.renderDevices = this.openedCollapses.includes('collapse-property-device')

    this.settlementsLoading = true
    ObjectApi.getSettlementByReferenceId([this.property.id])
      .then(response => {
        this.settlements = response.settlementObjects
        this.settlementsLoading = false
        this.$nextTick(() => {
          this.$emit('has-change', false)
        })
      })
    this.mortgagesLoading = true
    ObjectApi.getMortgagesByReferenceIds([this.property.id])
      .then(response => {
        this.mortgages = this.sortByDateMortgages(response.mortgages)
        this.mortgagesLoading = false

        // Load documents of mortgages
        let documentIds = []
        for (const mortgage of response.mortgages) {
          if (mortgage.documentIds.length > 0) {
            documentIds = documentIds.concat(mortgage.documentIds)
          }
        }
        if (documentIds.length > 0) {
          MediaApi.listDocuments(documentIds)
            .then(documents => {
              this.mortgageDocuments = documents
            })
        }
        this.$nextTick(() => {
          this.$emit('has-change', false)
        })
      })
    this.insurancesLoading = true
    ObjectApi.getInsurancesByReferenceId([this.property.id])
      .then(response => {
        this.insurancesLoading = false
        this.$nextTick(() => {
          this.$emit('has-change', false)
        })
        this.propertyInsurances = this.sortByDateInsurances(response.insurances)

        // Load documents of insurances
        let documentIds = []
        for (const insurance of response.insurances) {
          if (insurance.documentIds.length > 0) {
            documentIds = documentIds.concat(insurance.documentIds)
          }
        }

        if (documentIds.length > 0) {
          MediaApi.listDocuments(documentIds)
            .then(documents => {
              this.insurancesDocuments = documents
            })
        }
      })
    ObjectApi.getDevicesByReferenceIds([this.property.id])
      .then(response => {
        this.devices = response.devices.sort(
          function(a, b) {
            if (a.uninstallation === b.uninstallation) {
              return a.numericId > b.numericId ? 1 : -1
            }
            return a.uninstallation === null ? -1 : 1
          })
        this.insurancesLoading = false
        this.$nextTick(() => {
          this.$emit('has-change', false)
        })
      })
    ContactApi.getContacts(this.property.ownerId)
      .then(contacts => {
        this.propertyOwnerContacts = contacts.persons.concat(contacts.companies)
      })

    if (this.expandPropertyMortgageId !== '') {
      setTimeout(() => {
        const mortgageId = 'property_mortgage_' + this.expandPropertyMortgageId
        const element = document.getElementById(mortgageId)
        element.scrollIntoView(true)
        window.scrollBy(0, -50)
      }, 700)
    }
    if (this.expandPropertyInsuranceId !== '') {
      setTimeout(() => {
        const mortgageId = 'property_insurance_' + this.expandPropertyInsuranceId
        const element = document.getElementById(mortgageId)
        element.scrollIntoView(true)
        window.scrollBy(0, -50)
      }, 700)
    }
  },
  methods: {
    async addFile(event) {
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        this.mediaUpload = files[0]
        if (['image/png', 'image/jpeg', 'image/jpg'].includes(this.mediaUpload.type)) {
          await this.sendFile()
        } else {
          this.mediaUpload = null
          Vue.toasted.show(this.$tc('message.savingErrors.fileNotSupported'), { type: 'error' })
        }
      }
    },
    async sendFile() {
      this.mediaUploadProcessing = true
      const selectedFile = this.mediaUpload ? [this.mediaUpload] : []
      const response = await this.uploadMedia(selectedFile)
      if (response.length > 0) {
        const media = response[0]
        this.propertyImageMedia = media
        this.property.imageMediaId = media.id
        this.mediaUpload = null
      }
      this.mediaUploadProcessing = false
    },
    getBuildingList() {
      this.isBusy = true
      ObjectApi.getBuildingsListByIds(this.propertySelected.buildingIds)
        .then(async response => {
          this.buildingList = response.buildings
          if (this.propertySelected?.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
            this.getValueQuota()
          }
        })
    },
    getValueQuota() {
      for (let i = 0; i < this.buildingList.length; i++) {
        const buildingId = this.buildingList[i].id
        ObjectApi.getTotalValueQuotaForBuilding(buildingId)
          .then(response => {
            const obj = { buildingId: buildingId, valueQuota: response.totalValueQuota }
            this.valuesQuota.push(obj)
          })
      }
    },
    downloadAllDocuments(e) {
      e.preventDefault()
      const idsAndNames = this.propertyDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.$tc('message.propertiesView.documents') + ' - ' + this.propertySelected.name
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    goToEditMode() {
      localStorage.setItem('addGeneralPropertyDocument', 'true')
      this.$router.push({
        name: this.moduleRoutePrefix + 'PropertyEditingView',
        params: { id: this.$route.params.id },
        query: {
          view: 'property'
        }
      })
    },
    showImage() {
      if (this.readOnly && this.propertyImageMedia && this.propertyImageMedia.url) {
        this.galleryImages = [
          this.propertyImageMedia.url
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    cityChanged(event) {
      this.property.address.city = event.city
      this.property.address.state = event.state
    },
    async getCityFromZip() {
      if (this.property.address.zip.length > 3 && this.property.address.country === 'CH') {
          const zipData = await this.getZipInfo(this.property.address.zip)
          if (zipData) {
            this.property.address.state = zipData.state
            if (zipData.cities.length > 1) {
              this.isCitiesSuggested = true
              this.citiesValue = zipData.cities
            } else {
              this.isCitiesSuggested = false
              this.$nextTick(() => {
                this.property.address.city = zipData.cities[0].city
              })
            }
          }
      }
    },
    duplicateDevice(device) {
      const duplicatedDevice = copyObject(device)
      delete duplicatedDevice.id
      duplicatedDevice.internalId = Date.now()
      duplicatedDevice.isNewItem = true
      duplicatedDevice.documentIds = []
      this.devices.push(duplicatedDevice)
      this.$nextTick(() => {
        this.$emit('has-change', true)
        const indexDevice = this.devices.length - 1
        const domRect = this.$el.querySelector('#device-property-' + indexDevice)
        if (domRect) {
          domRect.scrollIntoView(true)
          this.$el.querySelector('#collapse-device-property-' + indexDevice + ' input').focus()
        }
      })
    },
    uploadImageClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.mediaUpload.$el.children
        btn[0].click()
      }
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('addGeneralPropertyDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document) {
      this.property.documentIds.push(document.id)
      this.propertyDocuments.push(document)
    },
    updateMortgage(mortgage) {
      const index = this.mortgages.findIndex(mo => mo.id === mortgage || mo.internalId === mortgage)
      if (index >= 0) {
        if (this.mortgages[index].id !== undefined) {
          this.$emit('has-change', true)
        }
      }
    },
    updateBankDetails(mortgage) {
      const index = this.bankDetails.findIndex(bd => bd.id === mortgage || bd.internalId === mortgage)
      if (index >= 0) {
        if (this.bankDetails[index].id !== undefined) {
          this.$emit('has-change', true)
        }
      }
    },
    versionAdded(updatedDocument) {
      this.propertyDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.propertyDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    deleteDocument(document) {
      this.$emit('has-change', true)
      this.property.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.property.documentIds.splice(index, 1)
        }
      }, this)

      const index = this.propertyDocuments.findIndex(doc => doc.id === document.id)
      if (index >= 0) {
        this.$delete(this.propertyDocuments, index)
      }
    },
    deleteMedia() {
      this.propertyImageMedia = null
      this.property.imageMediaId = ''
    },
    addNewMortgage() {
      this.mortgages.push({
        internalId: Date.now(),
        ownerId: this.property.ownerId,
        referenceId: this.property.id,
        documentIds: [],
        amortisationAmount: null,
        isNewItem: true,
        enableAmortizations: false,
        amortizations: [],
        amount: -1,
        annualAmortisation: -1,
        bankName: '',
        bankNumber: '',
        bic: '',
        designation: '',
        dueDateMonthsAmortization: [],
        dueDateMonthsInterestRate: [],
        inactiveFrom: {
          day: -1,
          month: -1,
          year: -1
        },
        interestRate: '',
        debitAccount: '',
        mortgageDesignation: '',
        mortgageeAddress: {
          city: '',
          country: '',
          street: '',
          zip: ''
        },
        number: '',
        terminableOn: '',
        validFrom: {
          day: -1,
          month: -1,
          year: -1
        }
      })
    },
    addNewBankDetails() {
      this.bankDetails.push({
        internalId: Date.now(),
        ownerId: this.property.ownerId,
        referenceId: this.property.id,
        documentIds: [],
        isNewItem: true,
        bankName: '',
        clearingNumber: '',
        bic: '',
        address: {
          city: '',
          country: '',
          street: '',
          zip: ''
        },
        validFrom: null,
        expiresAt: null,
        remark: ''
      })
    },
    addNewInsurance() {
      const insurance = {
        internalId: Date.now(),
        ownerId: this.property.ownerId,
        referenceId: this.property.id,
        documentIds: [],
        isNewItem: true,
        insuranceCompany: '',
        type: '',
        benefits: [],
        notes: '',
        amount: -1,
        yearlyCosts: -1,
        excess: -1,
        premiumPaidUntil: null
      }
      this.propertyInsurances.push(insurance)
      this.$nextTick(() => {
        const indexInsurance = this.propertyInsurances.length - 1
        const domRect = this.$el.querySelector('#insurance-i-' + indexInsurance)
        if (domRect) {
          domRect.scrollIntoView(true)
          this.$el.querySelector('#insurance-i-' + indexInsurance + ' input').focus()
        }
      })
    },
    addNewDevice() {
      const device = {
        internalId: Date.now(),
        acquisitionCosts: -1,
        numericId: 99999,
        ownerId: this.property.ownerId,
        cataloguePrice: -1,
        referenceId: this.property.id,
        installation: null,
        uninstallation: null,
        type: undefined,
        measureUntil: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        system: undefined,
        facilityPlant: undefined,
        bkpH: undefined,
        lifeSpan: -1,
        amount: -1,
        note: '',
        unit: 'DEVICE_UNIT_UNDEFINED',
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            amount: -1,
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            amount: -1,
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        documentIds: [],
        condition: 'DEVICE_CONDITION_NORMAL_WEAR',
        images: [],
        isNewItem: true
      }
      this.devices.push(device)
      this.$nextTick(() => {
        const indexDevice = this.devices.length - 1
        const domRect = this.$el.querySelector('#device-i-' + indexDevice)
        if (domRect) {
          domRect.scrollIntoView(true)
          this.$el.querySelector('#device-i-' + indexDevice + ' input').focus()
        }
      })
    },
    addNewSettlement() {
      const settlement = {
        internalId: Date.now(),
        isNewItem: true,
        ownerId: this.property.ownerId,
        active: true,
        additionalCostsBillingType: 'ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE',
        billingContactId: null,
        correspondenceContactId: null,
        billingStart: null,
        description: '',
        ownerIsDebtor: false,
        referenceId: this.property.id,
        rentAdjustments: [{
          additionalValues: [],
          internalId: Date.now(),
          advancePaymentValues: [],
          byDate: null,
          documentIds: [],
          dueDates: [],
          flatRateValues: []
        }]
      }
      this.settlements.push(settlement)
      this.$nextTick(() => {
        const indexSettlement = this.settlements.length - 1
        const domRect = this.$el.querySelector('#settlement-i-' + indexSettlement)
        if (domRect) {
          domRect.scrollIntoView(true)
          this.$el.querySelector('#settlement-i-' + indexSettlement + ' input').focus()
        }
      })
    },
    removeMortgage(id) {
      const index = this.mortgages.findIndex(mo => mo.id === id || mo.internalId === id)
      if (index >= 0) {
        if (this.mortgages[index].id !== undefined) {
          this.deletedMortgageIds.push(this.mortgages[index].id)
        }
        this.$delete(this.mortgages, index)
        this.$emit('has-change', true)
      }
    },
    removeBankDetails(id) {
      const index = this.bankDetails.findIndex(mo => mo.id === id || mo.internalId === id)
      if (index >= 0) {
        if (this.bankDetails[index].id !== undefined) {
          this.deletedBankDetailsIds.push(this.mortgages[index].id)
        }
        this.$delete(this.bankDetails, index)
        this.$emit('has-change', true)
      }
    },
    removeDevice(id) {
      if (id !== undefined) {
        ObjectApi.deleteDevice(this.devices[id].id)
      }
      this.$delete(this.devices, id)
    },
    removeSettlement(item) {
      if (item.id) {
        this.deletedSettlementObjects.push(item.id)
      }
      this.settlements = this.settlements.filter(s => (s.internalId && s.internalId !== item.internalId) || (s.id && s.id !== item.id))
      this.$emit('has-change', true)
    },
    removeInsurance(id) {
      const index = this.propertyInsurances.findIndex(ins => ins.id === id || ins.internalId === id)
      if (index >= 0) {
        if (this.propertyInsurances[index].id !== undefined) {
          ObjectApi.deleteInsurance(this.propertyInsurances[index].id)
        }
        this.$delete(this.propertyInsurances, index)
      }
      this.$emit('has-change', true)
    },
    openActivities(device) {
      if (device !== null && device !== undefined) {
        this.selectedDevice = device
        this.$emit('selected-device-for-history', device)
      }
    },
    openActivitiesMiddle(device) {
      let routeData = ''
      if (device !== null && device !== undefined) {
        if (this.isOwnerModule) {
          routeData = this.$router.resolve({
            name: 'OwnerPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        } else if (this.isAdminModule) {
          routeData = this.$router.resolve({
            name: 'AdminPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        } else if (this.isAssetModule) {
          routeData = this.$router.resolve({
            name: 'AssetPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        } else if (this.isAccountingModule) {
          routeData = this.$router.resolve({
            name: 'AccountingPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        }
      }
      window.open(routeData.href, '_blank')
    },
    async saveData() {
      this.$v.property.$touch()
      let valid = true
      let validDevice = true
      const errorArray = []
      if (this.$refs['property-mortgage']) {
        this.$refs['property-mortgage'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen() && (this.$refs.collapseMortgage && !this.$refs.collapseMortgage.visible)) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.mortgage') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs['property-settlement']) {
        this.$refs['property-settlement'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen() && (this.$refs.collapseSettlement && !this.$refs.collapseSettlement.visible)) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.settlements') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs['property-insurance']) {
        this.$refs['property-insurance'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.insurance') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs['property-device']) {
        this.$refs['property-device'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.devices.title') + this.$t('message.savingErrors.validationError2'))
            }
            validDevice = false
          }
        })
      }
      if (this.$refs['property-bank-details']) {
        this.$refs['property-bank-details'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen() && (this.$refs.collapseBankDetails && !this.$refs.collapseBankDetails.visible)) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.propertyBankDetails') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (errorArray.length > 0) {
        this.showManyToast(errorArray)
      }

      if (!this.$v.property.$invalid && valid && validDevice) {
        await this.$store.dispatch('onboarding/setOpenedCollapses')
        this.$emit('set-loading', true)
        const promises = []
        for (let index = 0; index < this.deletedSettlementObjects.length; index++) {
          promises.push(ObjectApi.deleteSettlement(this.deletedSettlementObjects[index]))
        }
        for (let index = 0; index < this.deletedMortgageIds.length; index++) {
          promises.push(ObjectApi.deleteMortgage(this.deletedMortgageIds[index]))
        }
        this.property.bankinfo = this.bankDetails
        promises.push(ObjectApi.updateProperty(this.property))
        if (this.mortgages.length > 0 && !this.isJanitorEditor) {
          for (const mortgage of this.mortgages) {
            if (mortgage.internalId) {
              promises.push(ObjectApi.createMortgage(mortgage))
            } else {
              promises.push(ObjectApi.updateMortgage(mortgage))
            }
          }
        }
        if (this.devices.length > 0) {
          for (const device of this.devices) {
            if (device.internalId) {
              promises.push(ObjectApi.createDevice(device))
            } else {
              promises.push(ObjectApi.updateDevice(device))
            }
          }
        }
        if (this.propertyInsurances.length > 0 && !this.isJanitorEditor) {
          for (const insurance of this.propertyInsurances) {
            if (insurance.internalId) {
              promises.push(ObjectApi.createInsurance(insurance))
            } else {
              promises.push(ObjectApi.updateInsurance(insurance))
            }
          }
        }
        if (this.settlements.length > 0 && !this.isJanitorEditor) {
          for (const settlement of this.settlements) {
            if (settlement.internalId) {
              promises.push(ObjectApi.createSettlement(settlement))
            } else {
              promises.push(ObjectApi.updateSettlement(settlement))
            }
          }
        }

        return Promise.all(promises)
          .then((response) => {
            const newAddedDevice = this.devices.filter(x => x.internalId)
            if (response.filter(x => x.device).length > 0) {
              this.devices = []
              response.forEach(element => {
                if (element.device) {
                  this.devices.push(element.device)
                }
              })
              this.devices = this.devices.sort(
                function(a, b) {
                  if (a.uninstallation === b.uninstallation) {
                    return a.numericId > b.numericId ? 1 : -1
                  }
                  return a.uninstallation === null ? -1 : 1
                })
            }
            if (response.filter(x => x.insurance).length > 0) {
              this.propertyInsurances = []
              response.forEach(element => {
                if (element.insurance) {
                  this.propertyInsurances.push(element.insurance)
                }
              })
            }
            if (response.filter(x => x.mortgage).length > 0) {
              this.mortgages = []
              response.forEach(element => {
                if (element.mortgage) {
                  this.mortgages.push(element.mortgage)
                }
              })
            }
            if (response.filter(x => x.settlementObject).length > 0) {
              this.settlements = []
              this.deletedSettlementObjects = []
              response.forEach(element => {
                if (element.settlementObject) {
                  this.settlements.push(element.settlementObject)
                }
              })
            }
            this.devices = this.devices.sort(
              function(a, b) {
                if (a.uninstallation === b.uninstallation) {
                  return a.numericId > b.numericId ? 1 : -1
                }
                return a.uninstallation === null ? -1 : 1
              })
            Vue.toasted.show(this.$t('message.successMessages.propertyCreation'), { type: 'success' })
            if (newAddedDevice.length > 0) {
              Vue.toasted.show(this.$t('message.successMessages.deviceSaved'), { type: 'success' })
            }
          })
          .catch(e => {
            if (e.response && e.response.data && e.response.data.code === 9) {
              Vue.toasted.show(this.$t('message.savingErrors.propertyWithOptedTenancies'), { type: 'error' })
            } else if (e.response && e.response.data && e.response.data.code === 3) {
              const component = []
              const current = this
              this.initRentComponents.forEach(c => {
                if (e.response.data.error.indexOf(c) !== -1) {
                  if (c.indexOf('FLAT_RATE_RENT') !== -1) {
                    component.push(current.$t('message.propertiesView.rentComponents.flatRateRentComponents.' + c))
                  } else if (c.indexOf('ADDITIONAL_RENT') !== -1) {
                    component.push(current.$t('message.propertiesView.rentComponents.additionalRentComponents.' + c))
                  } else if (c.indexOf('ADVANCE_PAYMENT_RENT_COMPONENT') !== -1) {
                    component.push(current.$t('message.propertiesView.rentComponents.advancePaymentRentComponents.' + c))
                  }
                }
              })
              Vue.toasted.show(this.$t('message.savingErrors.rentComponentStillInUse', { component: component.join(', ') }), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.property'), { type: 'error' })
            }
          })
          .finally(() => {
            this.$emit('set-loading', false)
          })
      } else {
        this.scrollToError()
      }
    },
    handleDragStart(itemId) {
      // Set the dragged item's ID in the data transfer object
      event.dataTransfer.setData('itemId', itemId)
    }
  },
  validations() {
    const validationProperty = {
      property: {
        name: {
          required,
          maxLength: maxLength(30)
        },
        plotArea: {
          minValue: minValue(-1),
          decimal
        },
        opted: {
          required
        },
        address: {
          zip: {
            required,
            isZipValid: isZipValid(this.property.address)
          },
          city: {
            required
          }
        }
      }
    }
    if (this.property.opted) {
      validationProperty.property.optedPortionKey = {
        required
      }
    }
    return validationProperty
  }
}
</script>
<style lang="scss" scoped>
.building-image {
  max-height: 200px;
  max-width: 100%;
}

.object-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 4px 12px;
}
.object-image-remove {
  position: absolute;
  z-index: 3;
  width: 17px;
  height: 16px;
  color: red;
  &.bg {
    left: 0;
    top: -3px;
    width: 14px;
    height: 22px;
    color: white;
  }
}
</style>

<template>
  <div class="col">
    <div
      slot="table-busy"
      class="text-center text-danger my-2" />
    <div
      class="row">
      <div class="col-md-5">
        <h3>{{ $t('message.processHandover.rooms.availableRooms') }}</h3>
        <draggable
          v-model="availableRooms"
          group="rooms"
          class="list-group"
          @end="updateRooms">
          <div
            v-for="room in availableRooms"
            :key="room.id"
            :class="['list-group-item', selectedRoomGroup.length > 0 && selectedRoomGroup.find(c => c.id === room.id) ? 'selected' : '']"
            class="list-group-item"
            @click="selectRoom(room, 'available', $event)">
            <span>{{ room.name }}</span>
          </div>
        </draggable>
      </div>
      <div class="arrows col-md-2">
        <button
          :disabled="!selectedRoomGroup || selectedRoomType !== 'available'"
          @click="moveSelectedRoomToSelected">
          <span>→</span>
        </button>
        <button
          :disabled="!selectedRoomGroup || selectedRoomType !== 'selected'"
          @click="moveSelectedRoomToAvailable">
          <span>←</span>
        </button>
      </div>

      <div class="col-md-5">
        <h3>{{ $t('message.processHandover.rooms.selectedRooms') }}</h3>
        <draggable
          v-model="selectedRooms"
          group="rooms"
          class="list-group"
          @end="updateRooms">
          <div
            v-for="room in selectedRooms"
            :key="room.id"
            :class="['list-group-item', selectedRoomGroup.length > 0 && selectedRoomGroup.find(c => c.id === room.id) ? 'selected' : '']"
            @click="selectRoom(room, 'selected', $event)">
            <coozzy-form-input
              v-if="selectedRoomGroup.length > 0 && selectedRoomGroup.find(c => c.id === room.id) && editingRoom"
              v-model="room.name"
              @blur="finishEdit(room)"
              @keyup.enter="finishEdit(room)" />
            <span v-else>{{ room.name }}</span>
            <coozzy-button
              v-if="!(selectedRoomGroup.length > 0 && selectedRoomGroup.find(c => c.id === room.id) && editingRoom)"
              design="transparent"
              class="p-0 ml-1 mb-2  float-right"
              @click="showDeleteConfirmation(room, $event)">
              <coozzy-delete-icon />
            </coozzy-button>
            <coozzy-button
              v-if="selectedRoomGroup.length > 0 && selectedRoomGroup.find(c => c.id === room.id) && editingRoom"
              design="transparent"
              class="p-0 mb-2 ml-2 save-icon"
              @click="finishEdit(room)">
              <coozzy-save-icon />
            </coozzy-button>
            <coozzy-button
              v-else
              design="transparent"
              class="p-0 mb-2 ml-2 float-right"
              @click="editRoom(room)">
              <coozzy-edit-icon />
            </coozzy-button>
          </div>
        </draggable>
        <coozzy-button
          class="col-sm-12 col-lg-4 col-md-4 float-md-right mt-3"
          design="prop-green"
          @click="addRoom">
          {{ $t('message.processHandover.rooms.addRoom') }}
        </coozzy-button>
        <b-modal
          :id="'confirmationModalRoom'"
          ref="confirmationModalRoom"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.processHandover.confirmationDeletion.title')">
          <template>
            <div class="col p-0">
              <p>{{ $t('message.processHandover.confirmationDeletion.body') }}</p>
            </div>
            <div class="col p-0">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('confirmationModalRoom')">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteRoom">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import { mapActions, mapGetters } from 'vuex'
import ContactApi from '@/misc/apis/ContactApi'
import draggable from 'vuedraggable'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon.vue'

export default {
  name: 'HandoverRoomsStep',
  components: {
    CoozzySaveIcon,
    CoozzyFormInput,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyEditIcon,
    draggable
  },
  mixins: [formatting, obj, onboarding, routeChecks, algolia],
  props: {
    object: {
      type: Object,
      required: true,
      default: () => {
        return null
      }
    },
    selfRooms: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      deletedRooms: [],
      rooms: [],
      availableRooms: [],
      selectedRooms: [],
      selectedEntries: [],
      filteredObjects: [],
      usedContacts: [],
      selectedRoomGroup: [],
      selectedRoomType: null,
      editingRoom: null
    }
  },
  computed: {
    employeeContacts() {
      let empContacts = this.contacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    }
  },
  watch: {
    selectedRooms(newVal) {
      this.$emit('selected-rooms', newVal, this.object)
    },
    deletedRooms(newVal) {
      this.$emit('deleted-rooms', newVal, this.object)
    },
    selfRooms(newVal, oldVal) {
      if ((oldVal.length === 0 && newVal.length > 0) || JSON.stringify(newVal) !== JSON.stringify(oldVal) || JSON.stringify(newVal) !== JSON.stringify(this.rooms)) {
        this.rooms = newVal
        this.updateSelectedRoom()
      }
    }
  },
  mounted() {
    if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE') {
      this.availableRooms = [
        {
          id: 'kitchen',
          name: this.$t('message.processHandover.rooms.kitchen'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'wetRoom',
          name: this.$t('message.processHandover.rooms.wetRoom'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'room',
          name: this.$t('message.processHandover.rooms.room'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'corridor_entrance',
          name: this.$t('message.processHandover.rooms.corridor_entrance'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'reduit_storage',
          name: this.$t('message.processHandover.rooms.reduit_storage'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'balcony_seatingArea',
          name: this.$t('message.processHandover.rooms.balcony_seatingArea'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'cellar',
          name: this.$t('message.processHandover.rooms.cellar'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        }
      ]
    } else if (this.object.category === 'PARKING_SPACE') {
      this.availableRooms = [{
 id: 'parking',
name: this.$t('message.processHandover.rooms.parking'),
dimensions: {
      area: null,
      height: null,
      length: null,
      volume: null,
      width: null
    },
    documentIds: [],
    imageMediaIds: [],
    note: '',
    ownerId: this.object.ownerId,
    referenceId: this.object.id
}]
    } else if (this.object.category === 'SECONDARY_ROOM') {
      this.availableRooms = [{
 id: 'secondaryRoom',
name: this.$t('message.processHandover.rooms.secondaryRoom'),
dimensions: {
      area: null,
      height: null,
      length: null,
      volume: null,
      width: null
    },
    documentIds: [],
    imageMediaIds: [],
    note: '',
    ownerId: this.object.ownerId,
    referenceId: this.object.id
}]
    }
    this.$emit('selected-rooms', this.selectedRooms, this.object)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    updateSelectedRoom() {
      this.rooms.forEach(room => {
        this.selectedRooms.push(room)
      })
      // remove duplicates
      this.selectedRooms = this.selectedRooms.filter((room, index, self) =>
          index === self.findIndex((t) => (
            t.id === room.id
          ))
      )
    },

    selectRoom(room, type, e) {
      console.log(e)
      if (e.srcElement.parentElement.classList.contains('fa-trash-can') || (this.editingRoom && e.srcElement.classList.contains('form-control'))) {
        e.preventDefault()
        return
      }
      if (this.selectedRoomType !== type) {
        this.selectedRoomGroup = []
      }
      const index = this.selectedRoomGroup.findIndex(d => d.id === room.id)
      if (index === -1) {
        this.selectedRoomGroup.push(room)
      } else {
        this.selectedRoomGroup.splice(index, 1)
      }
      this.selectedRoomType = type
    },
    moveSelectedRoomToSelected() {
      if (this.selectedRoomGroup.length > 0 && this.selectedRoomType === 'available') {
        this.selectedRoomGroup.forEach(room => {
          this.availableRooms = this.availableRooms.filter(
            (r) => r.id !== room.id
          )
          this.selectedRooms.push(room)
        })
        this.clearSelection()
      }
    },
    moveSelectedRoomToAvailable() {
      if (this.selectedRoomGroup.length > 0 && this.selectedRoomType === 'selected') {
        this.selectedRoomGroup.forEach(room => {
          this.selectedRooms = this.selectedRooms.filter(
            (r) => r.id !== room.id
          )
          this.availableRooms.push(room)
        })
        // this.selectedRooms = this.selectedRooms.filter(
        //   (r) => r.id !== this.selectedRoom.id
        // )
        // this.availableRooms.push(this.selectedRoom)
        this.clearSelection()
      }
    },
    showDeleteConfirmation(room, e) {
      e.preventDefault()
      if (!this.selectedRoomGroup.find(d => d.id === room.id)) {
        this.selectedRoomGroup.push(room)
      }
      this.$bvModal.show('confirmationModalRoom')
    },
    clearSelection() {
      this.selectedRoomGroup = []
      this.selectedRoomType = null
    },
    addRoom() {
      const newRoom = {
        id: Date.now(),
        dimensions: {
          area: null,
          height: null,
          length: null,
          volume: null,
          width: null
        },
        documentIds: [],
        imageMediaIds: [],
        name: this.$t('message.onBoarding.devices.room'),
        note: '',
        ownerId: this.object.ownerId,
        referenceId: this.object.id
      }
      this.selectedRooms.push(newRoom)
    },
    updateRooms(event) {
      // Optional: handle any updates after drag-and-drop
    },
    editRoom(room) {
      this.editingRoom = { ...room }
    },
    finishEdit(room) {
      if (room) {
        if (typeof room.id === 'string' && room.id.startsWith('room_')) {
          room.edited = true
        }
        let roomIndex = this.selectedRooms.findIndex(
          (r) => r.id === room.id
        )
        if (roomIndex !== -1) {
          this.$set(this.selectedRooms, roomIndex, room)
        }
      }
      this.editingRoom = null
    },
    deleteRoom() {
      // delete all items on selectedRooms from selectedRoomGroup by id
      this.selectedRoomGroup.forEach(roomGroup => {
        this.selectedRooms = this.selectedRooms.filter(r => r.id !== roomGroup.id)
        this.deletedRooms.push(roomGroup.id)
      })
      this.$bvModal.hide('confirmationModalRoom')
      this.clearSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.save-icon {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 9;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrows button {
  margin: 5px;
}

.list-group {
  min-height: 200px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}

.list-group-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.list-group-item.selected {
  background-color: #d3e2ff;
  border-color: #007bff;
}
</style>

import { ObjectdeviceDevice, ObjecttenancyTenancy } from './api'

const helper = {
  emptyTenancy: {
    internalId: Date.now(),
    ownerId: '',
    objectId: '',
    vacancy: false,
    activated: false,
    closed: false,
    contractAmendments: null,
    desiredMoveOutAt: null,
    familyFlat: false,
    finalInvoiceCreated: false,
    isLatest: false,
    note: '',
    numericId: '',
    id: '',
    reasonForTermination: '',
    returningAt: null,
    secondaryResidence: false,
    status: 'TENANCY_STATUS_NOT_ACTIVATED',
    terminationSentByTenantAt: null,
    unjustifiedUse: null,
    updatedAt: null,
    vacancyReason: 'VACANCY_REASON_UNDEFINED',
    contract: {
      activationDate: null,
      creationDate: null,
      intendedUse: '',
      licensePlate: '',
      number: '',
      type: 'UNDEFINED_CONTRACT_TYPE',
      rentType: 'RENT_TYPE_UNDEFINED',
      typeOfUse: 'TYPE_OF_USE_UNDEFINED'
    },
    subTenants: [],
    roommates: [],
    opted: false,
    taxable: null,
    tenant: {
      billingContactId: null,
      guarantorContactIds: [],
      correspondenceContactId: null,
      contactIds: [],
      industryName: '',
      industryCode: 0,
      numberOfPersons: -1,
      type: 'UNDEFINED_TENANT_TYPE'
    },
    deposit: {
      type: 'DEPOSIT_TYPE_NO_DEPOSIT',
      address: {
        country: 'CH',
        careOf: '',
        city: '',
        geo: null,
        mailbox: '',
        state: '',
        street: '',
        streetAddition: '',
        streetNumber: '',
        subUnit: '',
        zip: ''
      },
      amount: -1,
      dueDate: null,
      paidAt: null,
      paidOut: null,
      paidOutAt: null,
      paidAmount: -1,
      certificateReceivedAt: null,
      clearingNumber: -1,
      insuranceCompany: '',
      bic: '',
      bankAccount: '',
      bankName: '',
      paidOutAmount: -1,
      note: '',
      policyNumber: '',
      validFrom: null,
      validUntil: null,
      depositShares: [],
      dividedBySeveralPersons: false
    },
    renewalOptions: [],
    earlyRightOfTerminationOptions: [],
    rent: {
      advancePaymentValues: [],
      flatRateValues: [],
      additionalValues: [],
      dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      additionalCosts: -1,
      coldRent: -1,
      heatingCosts: -1,
      additionalCostsBillingType: 'ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE',
      monthlyRentDueBy: 1,
      countryIndex: {
        baseDate: '0',
        date: null,
        inflation: -1,
        possibleDate: null,
        possibleInflation: -1
      },
      referenceInterestRate: {
        date: null,
        rate: -10000,
        ratePossible: -10000
      },
      costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
      costIncreaseDate: null,
      costIncreaseValue: -1,
      adjustments: [
        {
          calculationBasis: 'TENANCY_CALCULATION_BASIS_DEFAULT',
          byDate: null,
          dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          additionalCosts: -1,
          additionalCostsPerMonth: -1,
          coldRent: -1,
          grossRent: -1,
          coldRentM2PerYear: -1,
          coldRentPerMonth: -1,
          grossRentPerMonth: -1,
          heatingCosts: -1,
          additionalCostsBillingType: 'UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE',
          countryIndex: {
            baseDate: '0',
            date: null,
            inflation: -1,
            possibleDate: null,
            possibleInflation: -1
          },
          referenceInterestRate: {
            date: null,
            rate: -10000,
            ratePossible: -10000
          },
          costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
          costIncreaseDate: null,
          costIncreaseValue: -1,
          monthlyRentDueBy: 1,
          inArrears: false,
          advancePaymentValues: [],
          flatRateValues: [],
          additionalValues: [],
          retentionOfRents: [],
          documentIds: [],
          costIncreasePossibleDate: null,
          finalized: false
        }
      ],
      firstRentAdjustmentPossibleAt: null,
      inArrears: false,
      interval: 'UNDEFINED_TENANCY_PAYMENT_INTERVAL'
    },
    terminationTenant: {
      earliestDate: null,
      possibleMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      periodInMonths: -1,
      tacitContractRenewal: 'TACIT_CONTRACT_RENEWAL_UNDEFINED'
    },
    terminationLandlord: {
      earliestDate: null,
      possibleMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      periodInMonths: -1,
      tacitContractRenewal: 'TACIT_CONTRACT_RENEWAL_UNDEFINED'
    },
    startingAt: null,
    endingAt: null,
    documentIds: [],
    terminatedBy: 'TERMINATED_BY_UNDEFINED',
    terminationType: 'TERMINATION_TYPE_UNDEFINED',
    terminationReason: [],
    liabilityEndsAt: null,
    nextPossibleMoveInAt: null,
    terminationCreatedAt: null,
    terminatedOn: null,
    terminationJustification: '',
    movedOutAt: null,
    indexData: {
      additionalCostsAdjustmentAllowed: false,
      belowInitialRentAllowed: false,
      contractPercentImputedAtIndex: -1,
      maximumRate: -1,
      referenceMonthForIndex: -1,
      rentAdjustmentAdditionalBenefit: false,
      rentAdjustmentByMonths: -1,
      rentAdjustmentByPoints: -1,
      rentAdjustmentMonths: [],
      rentAdjustmentNotification: {
        unit: 'RENT_ADJUSTMENT_NOTIFICATION_DAYS',
        value: 0
      }
    }
  } as ObjecttenancyTenancy,
  emptyDevice: {
    internalId: '',
    ownerId: '',
    referenceId: '',
    installation: null,
    uninstallation: null,
    measureUntil: null,
    measure: 'DEVICE_MEASURE_UNDEFINED',
    type: 'DEVICE_TYPE_UNDEFINED',
    system: 'DEVICE_SYSTEM_UNDEFINED',
    facilityPlant: 'DEVICE_FACILITY_PLANT_UNDEFINED',
    bkpH: '',
    lifeSpan: 0,
    amount: 0,
    unit: 'DEVICE_UNIT_UNDEFINED',
    acquisitionCosts: -1,
    cataloguePrice: -1,
    maintenance: {
      contact: {
        userReference: '',
        name: '',
        accountId: '-1'
      },
      contract: '',
      costs: {
        currency: 'CHF',
        amount: 0,
        interval: 'UNDEFINED_INTERVAL'
      },
      lastMaintenance: null,
      nextMaintenance: null,
      subscriptionStart: null,
      subscriptionEnd: null,
      note: ''
    },
    inspection: {
      contact: {
        userReference: '',
        name: '',
        accountId: '-1'
      },
      contract: '',
      costs: {
        currency: 'CHF',
        amount: 0,
        interval: 'UNDEFINED_INTERVAL'
      },
      lastOfficialControl: null,
      nextOfficialControl: null,
      subscriptionStart: null,
      subscriptionEnd: null,
      note: ''
    },
    revision: {
      contact: {
        userReference: '',
        name: '',
        accountId: '-1'
      },
      contract: '',
      costs: {
        currency: 'CHF',
        amount: 0,
        interval: 'UNDEFINED_INTERVAL'
      },
      lastOfficialRevision: null,
      nextOfficialRevision: null,
      subscriptionStart: null,
      subscriptionEnd: null,
      note: ''
    },
    condition: 'DEVICE_CONDITION_NORMAL_WEAR',
    note: '',
    documentIds: [],
    images: [],
    isNewItem: true,
    lockingKey: {
      combinedKeys: false,
      combinedKeysNote: '',
      description: '',
      lockingPlanNumber: ''
    },
    brand: '',
    conditionNote: '',
    id: '',
    manufacturer: '',
    model: '',
    numericId: '',
    roomId: '',
    serialNumber: '',
    warrantyEnd: null
  } as ObjectdeviceDevice
}
export { helper }

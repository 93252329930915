// @ts-nocheck
import MessageApi from '@/misc/apis/MessageApi'
import Vue from 'vue'
import store from '../store'
import { i18n } from '@/internationalization/i18nSetup'
import { html } from '@/mixins/html'

export default {
  mixins: [html],
  namespaced: true,
  state: {
    sendMailData: {
      id: null,
      isDraft: false,
      hasContext: true,
      secondaryTitle: false,
      onlyCurrentContact: false,
      contacts: [],
      contactsWithoutEmail: [],
      contactsWithObject: [],
      cc: [],
      bcc: [],
      preselectedContact: null,
      referenceIds: [],
      emailToReply: null,
      emailToForward: null,
      useBcc: false,
      toAllTo: false,
      bccReferenceIdsByContacts: {},
      subject: null,
      additionalText: null,
      renderWithObjectId: '',
      sentMails: [],
      attachments: [],
      attachmentMediaIds: []
    },
    mailsLoadedAt: 0,
    mailsDraftLoadedAt: 0,
    readMails: [],
    unreadMails: [],
    changingStatusMails: [],
    mailboxEntries: [],
    accountMailbox: [],
    signatureId: null,
    mailboxesForUserIdLoading: [],
    mailboxesForAccountIdLoading: [],
    signatureEntries: [],
    signaturesLoading: [],
    templatesLoading: false,
    templatesTimestamp: 0,
    templates: [],
    numberMessage: 0,
    numberDraftMessage: 0,
    loading: false,
    selectedEntries: [],
    confidentialMailDomain: [],
    showAllPlaceholders: false
  },
  getters: {
    getSentMails: (state) => {
      return state.sendMailData.sentMails
    },
    getMailboxEntries: (state) => {
      return state.mailboxEntries
    },
    getMailboxesForUserId: (state) => (userId) => {
      const entry = state.mailboxEntries.find(entry => entry.userId === userId)

      if (!entry) {
        return []
      }

      return entry.mailboxes
    },
    getMailboxesForAccountId: (state) => (accountId) => {
      const entry = state.accountMailbox.find(entry => entry.accountId === accountId)

      if (!entry) {
        return []
      }

      return entry.mailboxes
    },
    getSignatureForUserId: (state) => (userId, signatureId = null) => {
      let entry: null
      if (userId && signatureId !== null && signatureId !== '') {
        entry = state.signatureEntries.find(entry => entry.userId === userId && signatureId === entry.signatureId)
      } else if (userId === null) {
        entry = state.signatureEntries.find(entry => entry.signatureId === signatureId)
      } else {
        entry = state.signatureEntries.find(entry => entry.userId === userId && entry.signatureId === null)
      }
      if (!entry) {
        return ''
      }
      return entry?.signature || ''
    },
    getUnreadMail(state) {
      return state.unreadMails
    },
    getChangingStatusMails(state) {
      return state.changingStatusMails
    },
    getSelectedEntries(state) {
      return state.selectedEntries
    },
    getShowAllPlaceholders(state) {
      return state.showAllPlaceholders
    }
  },
  mutations: {
    resetSendMailData(state, withEmail = true) {
      const cc = state.sendMailData.contactsWithoutEmail
      if (withEmail) {
        Vue.set(state, 'sendMailData', {
          id: null,
          hasContext: true,
          secondaryTitle: false,
          onlyCurrentContact: false,
          contacts: [],
          cc: [],
          bcc: [],
          contactsWithoutEmail: [],
          contactsWithObject: [],
          preselectedContact: null,
          referenceIds: [],
          emailToReply: null,
          emailToForward: null,
          useBcc: false,
          toAllTo: false,
          bccReferenceIdsByContacts: {},
          renderWithObjectId: '',
          subject: null,
          additionalText: null,
          sentMails: [],
          attachments: [],
          attachmentMediaIds: [],
          isDraft: false
        })
      } else {
        Vue.set(state, 'sendMailData', {
          id: null,
          hasContext: true,
          secondaryTitle: false,
          onlyCurrentContact: false,
          contacts: [],
          preselectedContact: null,
          referenceIds: [],
          contactsWithoutEmail: cc,
          contactsWithObject: [],
          cc: [],
          bcc: [],
          emailToReply: null,
          emailToForward: null,
          useBcc: false,
          toAllTo: false,
          bccReferenceIdsByContacts: {},
          renderWithObjectId: '',
          subject: null,
          additionalText: null,
          sentMails: [],
          attachments: [],
          attachmentMediaIds: [],
          isDraft: false
        })
      }
    },
    setEmailToForwardAsAttachment(state, value) {
      Vue.set(state.sendMailData, 'attachments', value)
    },
    setSendMailHasContext(state, hasContext) {
      // Convert hasContext to a boolean with !!
      Vue.set(state.sendMailData, 'hasContext', !!hasContext)
    },
    setSendMailSecondaryTitle(state, sup) {
      Vue.set(state.sendMailData, 'secondaryTitle', !!sup)
    },
    setRenderWithObjectId(state, value) {
      Vue.set(state.sendMailData, 'renderWithObjectId', value)
    },
    setSendMailOnlyCurrentContact(state, value) {
      Vue.set(state.sendMailData, 'onlyCurrentContact', !!value)
    },
    setSendMailId(state, id) {
      Vue.set(state.sendMailData, 'id', id)
    },
    setSendMailAttachmentMediaIds(state, attachments) {
      if (!Array.isArray(attachments)) {
        throw new Error('Attachments need to be an array')
      }
      Vue.set(state.sendMailData, 'attachmentMediaIds', attachments)
    },
    setSendMailIsDraft(state, value) {
      Vue.set(state.sendMailData, 'isDraft', !!value)
    },
    setSendMailBodyHtml(state, value) {
      Vue.set(state.sendMailData, 'bodyHtml', value)
    },
    setSendMailContacts(state, contacts) {
      if (!Array.isArray(contacts)) {
        throw new Error('Contacts need to be an array')
      }
      Vue.set(state.sendMailData, 'contacts', contacts)
    },
    setSendMailCC(state, cc) {
      if (!Array.isArray(cc)) {
        throw new Error('Contacts need to be an array')
      }
      Vue.set(state.sendMailData, 'cc', cc)
    },
    setSendMailBCC(state, bcc) {
      if (!Array.isArray(bcc)) {
        throw new Error('Contacts need to be an array')
      }
      Vue.set(state.sendMailData, 'bcc', bcc)
    },
    setSendMailContactsWithoutEmail(state, contacts) {
      if (!Array.isArray(contacts)) {
        throw new Error('Contacts need to be an array')
      }
      Vue.set(state.sendMailData, 'contactsWithoutEmail', contacts)
    },
    setSendMailContactsWithObject(state, list) {
      if (!Array.isArray(list)) {
        throw new Error('List need to be an array')
      }
      Vue.set(state.sendMailData, 'contactsWithObject', list)
    },
    setSendMailPreselectedContact(state, contact) {
      Vue.set(state.sendMailData, 'preselectedContact', contact)
    },
    setSendMailReferenceIds(state, referenceIds) {
      if (!Array.isArray(referenceIds)) {
        throw new Error('ReferenceIds need to be an array')
      }
      Vue.set(state.sendMailData, 'referenceIds', referenceIds)
    },
    setSendMailEmailToReply(state, emailToReply) {
      if (emailToReply?.bodyText && !emailToReply.bodyHtml) {
        emailToReply.bodyHtml = '<br><br>' + emailToReply.bodyText.replaceAll('\n', '<br>')
      }
      Vue.set(state.sendMailData, 'emailToReply', emailToReply)
    },
    setSendMailEmailToForward(state, emailToForward) {
      if (emailToForward?.bodyText && !emailToForward.bodyHtml) {
        emailToForward.bodyHtml = '<br><br>' + emailToForward.bodyText.replaceAll('\n', '<br>')
      }
      Vue.set(state.sendMailData, 'emailToForward', emailToForward)
    },
    setUseBcc(state, useBcc) {
      Vue.set(state.sendMailData, 'useBcc', useBcc)
    },
    setToAllTo(state, toAll) {
      Vue.set(state.sendMailData, 'toAllTo', toAll)
    },
    setBccReferenceIds(state, bccReferenceIdsByContacts) {
      Vue.set(state.sendMailData, 'bccReferenceIdsByContacts', bccReferenceIdsByContacts)
    },
    setSubject(state, subject) {
      Vue.set(state.sendMailData, 'subject', subject)
    },
    setAdditionalText(state, additionalText) {
      Vue.set(state.sendMailData, 'additionalText', additionalText)
    },
    addSentMail(state, mail) {
      state.sendMailData.sentMails.push(mail)
    },
    setMailsLoadedAt(state, timestamp) {
      state.mailsLoadedAt = timestamp
    },
    setMailsDraftLoadedAt(state, timestamp) {
      state.mailsDraftLoadedAt = timestamp
    },
    addReadMail(state, { mailId, accountId }) {
      state.unreadMails = state.unreadMails.filter(e => e.mailId !== mailId || e.accountId !== accountId)
      state.readMails.push({ mailId: mailId, accountId: accountId })
    },
    addUnreadMail(state, { mailId, accountId }) {
      state.readMails = state.readMails.filter(e => e.mailId !== mailId || e.accountId !== accountId)
      state.unreadMails.push({ mailId: mailId, accountId: accountId })
    },
    setChangingStatusMails(state, mailIds) {
      state.changingStatusMails = state.changingStatusMails.concat(mailIds)
    },
    setSelectedEntries(state, selectedEntries) {
      state.selectedEntries = selectedEntries
    },
    setShowAllPlaceholders(state, showAllPlaceholders) {
      state.showAllPlaceholders = showAllPlaceholders
    },
    resetStatusMails(state) {
      state.changingStatusMails = []
    },
    setMailboxesForUserId(state, { userId, mailboxes }) {
      const newEntry = {
        userId: userId,
        mailboxes: mailboxes,
        timestamp: Date.now()
      }

      const index = state.mailboxEntries.findIndex(entry => entry.userId === userId)
      if (index === -1) {
        state.mailboxEntries.push(newEntry)
      } else {
        Vue.set(state.mailboxEntries, index, newEntry)
      }

      state.mailboxesForUserIdLoading = state.mailboxesForUserIdLoading.filter(id => id === userId)
    },
    setMailboxesForAccountId(state, { accountId, mailboxes }) {
      const newEntry = {
        accountId: accountId,
        mailboxes: mailboxes,
        timestamp: Date.now()
      }

      const index = state.accountMailbox.findIndex(entry => entry.accountId === accountId)
      if (index === -1) {
        state.accountMailbox.push(newEntry)
      } else {
        Vue.set(state.accountMailbox, index, newEntry)
      }

      state.mailboxesForAccountIdLoading = state.mailboxesForAccountIdLoading.filter(id => id === accountId)
    },
    setMailboxesForUserIdLoading(state, userId) {
      state.mailboxesForUserIdLoading.push(userId)
    },
    setMailboxesForAccountIdLoading(state, userId) {
      state.mailboxesForAccountIdLoading.push(userId)
    },
    resetMailboxes(state) {
      Vue.set(state, 'mailboxEntries', [])
      Vue.set(state, 'mailboxesForUserIdLoading', [])
    },
    resetSignatures(state) {
      Vue.set(state, 'signatureEntries', [])
      Vue.set(state, 'signaturesLoading', [])
    },
    setSignatureForUserId(state, { userId, signature, signatureId = '' }) {
      const newEntry = {
        userId: userId,
        signature: signature,
        timestamp: Date.now(),
        signatureId: signatureId
      }

      const index = state.signatureEntries.findIndex(entry => entry.userId === userId && entry.signatureId === signatureId)
      if (index === -1) {
        state.signatureEntries.push(newEntry)
      } else {
        Vue.set(state.signatureEntries, index, newEntry)
      }

      state.signaturesLoading = state.signaturesLoading.filter(id => id === userId).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    },
    setSignatureLoading(state, userId) {
      state.signaturesLoading.push(userId)
    },
    setTemplates(state, value) {
      const afterHtmlExtraction = []
      for (const template of value) {
        template.messageWithoutHtml = html.methods.decodeHtml(template.message.replace(/<[^>]+>/g, ''))
        afterHtmlExtraction.push(template)
      }
      state.templates = afterHtmlExtraction
      state.templatesTimestamp = Date.now()
      state.templatesLoading = false
    },
    setTemplatesLoading(state) {
      state.templatesLoading = true
    },
    setConfidentialMailDomain(state, value) {
      state.confidentialMailDomain = value
    },
    resetTemplateTimestamp(state) {
      state.templatesTimestamp = 0
    },
    setNumberMessage: (state, numberMessage) => {
      Vue.set(state, 'numberMessage', numberMessage)
      state.mailsLoadedAt = Date.now()
      state.loading = false
    },
    setNumberDraftMessage: (state, numberDraftMessage) => {
      Vue.set(state, 'numberDraftMessage', numberDraftMessage)
      state.mailsDraftLoadedAt = Date.now()
      state.loading = false
    },
    setLoading: (state) => {
      state.loading = true
    }
  },
  actions: {
    loadMailboxesForUserId: async ({ commit, state }, userId) => {
      const storedMailboxEntry = state.mailboxEntries.find(entry => entry.userId === userId)
      // If no loading is already triggered AND if no mailbox entry was found
      // OR it's older than 10 minutes -> Load new data
      if (!state.mailboxesForUserIdLoading.includes(userId) &&
        (!storedMailboxEntry || (Date.now() - storedMailboxEntry.timestamp) > 600000)) {
        try {
          commit('setMailboxesForUserIdLoading', userId)
          const response = await MessageApi.listMailboxesByUserId(userId)
          commit('setMailboxesForUserId', { userId: userId, mailboxes: response.mailboxes.length > 0 ? response.mailboxes.filter(x => x.active === true) : [] })
        } catch (e) {
          console.error(e)
        }
      }
    },
    loadMailboxesForAccountId: async ({ commit, state }, accountId) => {
      const storedMailboxEntry = state.accountMailbox.find(entry => entry.accountId === accountId)
      // If no loading is already triggered AND if no mailbox entry was found
      // OR it's older than 10 minutes -> Load new data
      if (!state.mailboxesForAccountIdLoading.includes(accountId) &&
        (!storedMailboxEntry || (Date.now() - storedMailboxEntry.timestamp) > 600000)) {
        try {
          commit('setMailboxesForAccountIdLoading', accountId)
          const response = await MessageApi.listMailboxes(accountId)
          commit('setMailboxesForAccountId', { accountId: accountId, mailboxes: response.mailboxes.length > 0 ? response.mailboxes.filter(x => x.active === true) : [] })
        } catch (e) {
          console.error(e)
        }
      }
    },
    resetMailboxes: async ({ commit }) => {
      commit('resetMailboxes')
    },
    loadSignatureForUserId: async ({ commit, state }, { accountId, userId, signatureId = null }) => {
      const storedSignatureEntry = state.signatureEntries.find(entry => entry.userId === userId)
      // If no loading is already triggered AND if no signature entry was found
      // OR it's older than 10 minutes -> Load new data
      if ((!state.signaturesLoading.includes(userId) &&
        (!storedSignatureEntry || (Date.now() - storedSignatureEntry.timestamp) > 600000)) || signatureId !== null) {
        try {
          commit('setSignatureLoading', userId)
          const response = signatureId && signatureId !== '' ? await MessageApi.getSignatureRenderById(signatureId, userId) : await MessageApi.getSignatureRender(accountId, userId)
          commit('setSignatureForUserId', { userId: userId, signature: response.signature, signatureId: signatureId || '' })
        } catch (e) {
          if (!e.response || e.response.status !== 404) {
            // Vue.toasted.show(i18n.t('message.loadingErrors.signature'), { type: 'error' })
          } else {
            commit('setSignatureForUserId', { userId: userId, signature: '' })
          }
        }
      }
    },
    resetSignatures: async ({ commit }) => {
      commit('resetSignatures')
    },
    loadTemplateList: async ({ commit, state }, ownerId) => {
      // Check if templates are older than 10 minutes
      if (!state.templatesLoading && ((Date.now() - state.templatesTimestamp) > 600000 || state.templates.length === 0)) {
        try {
          commit('setTemplatesLoading')
          const response = await MessageApi.getTemplateList(ownerId)
          commit('setTemplates', response.templates)
        } catch (e) {
          Vue.toasted.show(i18n.t('message.loadingErrors.template'), { type: 'error' })
        }
      }
    },
    resetTemplateList: async ({ commit, dispatch }, ownerId) => {
      commit('resetTemplateTimestamp')
      dispatch('loadTemplateList', ownerId)
    },
    loadEmailCounts: async ({ commit, state }, obj) => {
      return new Promise((resolve, reject) => {
        // Setup watcher for accountId if not yet available
        if (!store.getters['user/getUserId']) {
          commit('setLoading')

          store.watch((watchedState, getters) => getters['user/getUserId'], value => {
            let mailboxIds = ''
            if (localStorage.getItem('filterMailboxesMessagesOverview')) {
              mailboxIds = localStorage.getItem('filterMailboxesMessagesOverview')
            } else {
              mailboxIds = obj.mailboxIds
            }

            MessageApi.countUnreadMails(mailboxIds)
              .then(numberMessageResponse => {
                commit('setNumberMessage', numberMessageResponse.count)
                resolve(numberMessageResponse.count)
              })
              .catch(e => {
                console.error(e)
                reject(e)
              })
          })
        } else if (obj.force || (state.loading === false && (state.mailsLoadedAt === 0 || (Date.now() - state.mailsLoadedAt) >= 29000))) {
          // If force OR loading is not already triggered and stored mails count are older than 30 seconds -> Load new data
          commit('setLoading')

          let mailboxIds = ''
          if (localStorage.getItem('filterMailboxesMessagesOverview')) {
            mailboxIds = localStorage.getItem('filterMailboxesMessagesOverview')
          } else {
            mailboxIds = obj.mailboxIds
          }

          // Load and sort users
          MessageApi.countUnreadMails(mailboxIds)
            .then(numberMessageResponse => {
              commit('setNumberMessage', numberMessageResponse.count)
              resolve(state.numberMessage)
            })
            .catch(e => {
              console.error(e)
              reject(e)
            })
        } else {
          resolve(state.numberMessage)
        }
      })
    },
    loadDraftEmailCounts: async ({ commit, state }, force) => {
      return new Promise((resolve, reject) => {
        // Setup watcher for accountId if not yet available
        if (!store.getters['user/getUserId']) {
          commit('setLoading')

          store.watch((watchedState, getters) => getters['user/getUserId'], value => {
            MessageApi.countDrafts()
                .then(numberMessageResponse => {
                  commit('setNumberDraftMessage', numberMessageResponse.amount)
                  resolve(numberMessageResponse.amount)
                })
                .catch(e => {
                  console.error(e)
                  reject(e)
                })
          })
        } else if (force || (state.loading === false && (state.mailsDraftLoadedAt === 0 || (Date.now() - state.mailsDraftLoadedAt) > 30000))) {
          // If force OR loading is not already triggered and stored mails count are older than 30 seconds -> Load new data
          commit('setLoading')

          // Load and sort users
          MessageApi.countDrafts()
              .then(numberMessageResponse => {
                commit('setNumberDraftMessage', numberMessageResponse.amount)
                resolve(state.numberDraftMessage)
              })
              .catch(e => {
                console.error(e)
                reject(e)
              })
        } else {
          resolve(state.numberDraftMessage)
        }
      })
    },
    loadConfidentialMailDomain: async ({ commit, state }, accountId) => {
      if (state.confidentialMailDomain.length === 0) {
        try {
          const response = await MessageApi.listConfidentialEmails(accountId)
          commit('setConfidentialMailDomain', response.emails)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}

<template>
  <div
    class="root">
    <div class="row">
      <div class="col">
        <label
          v-if="name"
          :for="name"
          class="mb-0">
          {{ name }}
          <coozzy-info-circle-icon
            v-if="tooltipText !== ''"
            v-b-tooltip="{ container: '#app', title: tooltipText }"
            class="ml-2" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span
          v-if="forcedPlaceholder !== '' && !isReadOnly"
          class="limit text-grey-light">
          {{ forcedPlaceholder }}
        </span>
        <input
          v-if="!isReadOnly"
          v-model="displayValue"
          type="text"
          class="form-control form-control-sm"
          :class="[forcedPlaceholder !== '' && !isReadOnly ? 'padding-right-80px' : '', checkValide]"
          :disabled="disabled"
          :placeholder="placeholder"
          @blur="isInputActive = false"
          @focus="isInputActive = true"
          @keypress="isDecimalOrNumber">
        <span
          v-else
          class="show-data">
          <span v-if="filter === 'formatArea'">{{ value | formatArea }}</span>
          <span v-else-if="filter === 'formatMeter'">{{ value | formatMeter }}</span>
          <span v-else-if="filter === 'formatVolume'">{{ value | formatVolume }}</span>
          <span v-else-if="filter === 'formatPrice'">{{ value | formatPrice }}</span>
          <span v-else-if="filter === 'formatPriceDecimal'">{{ value | formatPrice('CHF', true) }}</span>
          <span v-else-if="filter === 'formatNumber'">{{ value | formatNumber }}</span>
          <span v-else-if="filter === 'formatPercent'">{{ value | formatPercent }}</span>
          <span v-else-if="filter === 'formatMeterDecimal'">{{ value | formatMeter }}</span>
          <span v-else>{{ value !=='' && value !== null ? value : '-' }}</span>
          <span v-if="forcedPlaceholder !== ''"> {{ forcedPlaceholder }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoozzyFormInputNumeric',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isDecimal: {
      type: Boolean,
      default: false
    },
    isOnlyDecimal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isInteger: {
      type: Boolean,
      default: false
    },
    isIntegerFormat: {
      type: Boolean,
      default: false
    },
    isFormated: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: [String, Number],
      default: '',
      required: false
    },
    filter: {
      type: String,
      default: ''
    },
    step: {
      type: Number,
      default: 1
    },
    forcedPlaceholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        const types = [
          'color', 'date', 'datetime-local', 'email',
          'file', 'hidden', 'image', 'month',
          'number', 'password', 'radio', 'range',
          'reset', 'search', 'submit', 'tel',
          'text', 'time', 'url', 'week'
        ]
        return types.includes(value)
      }
    },
    checkValide: {
      type: String,
      default: ''
    },
    limited: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    decimalFraction: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      isInputActive: false
    }
  },
  computed: {
    displayValue: {
      get: function() {
        if (this.value !== null) {
          if (this.filter === 'formatPrice') {
            if (Number.isInteger(this.value)) {
              return this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
            } else if (!this.isInputActive) {
              if (this.value.toString().endsWith('.')) {
                return this.value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              }
              return this.value.toFixed(this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
            } else {
              return this.value
            }
          } else if (this.isInputActive) {
            // Cursor is inside the input field. unformat display value for user
            if (this.isFormated) {
              return this.value.toFixed((this.value % 1) === 0 ? 0 : this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
            }
            return this.value.toString()
          } else {
            // User is not modifying now. Format display value for user interface
            if (this.isInteger === true) {
              if (this.isIntegerFormat === true) {
                return this.value
              } else {
                return this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              }
            }
            if (this.isDecimal === true) {
              if (this.value % 1 === 0 && this.isFormated) {
                return this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              } else if (this.value % 1 === 0 && !this.isOnlyDecimal) {
                return this.value.toString().replace(/\.00$/, '')
              } else {
                return this.value.toFixed(this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              }
            }
            return this.value.toFixed(this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
          }
        }

        return this.value
      },
      set: function(modifiedValue) {
        let newValue
        if (this.isInteger === true) {
          newValue = parseInt(modifiedValue.replace(/[^0-9-]/g, '').replace(/(?!^)-/g, ''))
        } else {
          newValue = modifiedValue.endsWith('.') ? parseFloat(modifiedValue.replace(',', '.').replace('\'', '')) : parseFloat(modifiedValue.replace(',', '.').replace(/[^\d.]/g, ''))
        }
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = null
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    isDecimalOrNumber ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if (this.isDecimal === true) {
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 44 && keyCode !== 46) {
          $event.preventDefault()
        } else if (this.limited) {
          if (this.displayValue) {
            const value = this.displayValue?.toString() + $event.key
            if (parseFloat(value) > 99) {
              $event.preventDefault()
            }
            if (value.indexOf('.') > -1 && (value.split('.')[1].length > 2)) {
              $event.preventDefault()
            }
            if (value.indexOf('.') > -1 && (value.split('.')[1].length > 2) && (value.split('.')[0].length > 1)) {
              $event.preventDefault()
            }
          }
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 45) { // 46 is dot and 188 is comma
          $event.preventDefault()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
    input {
      padding: 0.2em 8px;
    }
  }
  .limit {
    position: absolute;
    right: 2rem;
    top: 0;
    line-height: 30px;
  }
</style>
